import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Typography } from '../styles/TextStyles'
import { ColorPaletteMain as Color } from '../styles/ColorPalette'
import { spacing } from '../styles/Spacing'
import { useAuth0 } from "@auth0/auth0-react";
import { NAVBAR_WIDTH } from '../styles/Constants'

interface NavigationProps {
  items?: Array<any>
}

export const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
  const { logout } = useAuth0();
  return (
    <NavbarWrapper>
      <MainNav>
        <LogoWrapper href="/">
          <img src="images/logo.png" alt="hope logo" />
        </LogoWrapper>
        {/* <NavItem linkTo="/" key="home-link" icon="far fa-list-alt" label="Overview" /> */}
        <NavItem linkTo="/" key="purchases-link" icon="far fa-credit-card" label="Manage Purchases" />
        {/* <NavItem linkTo="/purchases" key="purchases-link" icon="far fa-credit-card" label="Manage Purchases" /> */}
        <NavItem disabled linkTo="/chat" key="chat-link" icon="far fa-comments" label="Chat" />
        <NavItem disabled linkTo="/call" key="call-link" icon="far fa-address-card" label="Voice / Video Call" />
        <NavItem disabled linkTo="/clinic-details" key="clinic-link" icon="far fa-hospital" label="Clinic Details" />
        <NavItem disabled linkTo="/feedback" key="clinic-link" icon="far fa-star" label="Patient Feedback" />
        <NavItem disabled linkTo="/user-management" key="user-link" icon="far fa-address-book" label="User Management" />
      </MainNav>
      <SettingsNav>
        <NavItem href="https://hopeapp-clinic.com/helpcenter" target="_blank" key="help-link" icon="far fa-question-circle" label="Help" />
        <NavItem disabled linkTo="/profile" key="profile-link" icon="far fa-user-circle" label="Profile" />
        <NavItem onClick={() => { logout({ returnTo: window.location.origin }) }} key="logout-link" icon="far fa-share-square" label="Logout" />
      </SettingsNav>
    </NavbarWrapper>
  );
}

interface NavItemProps {
  linkTo?: string
  label: string
  key: string
  icon: string
  disabled?: boolean
  href?: string
  target?: string
  onClick?: () => void
}

const NavItem:React.FC<NavItemProps> = (props: NavItemProps) => {
  const content = <StyledLink disabled={props.disabled}>
    <NavIcon className={props.icon} />
    {props.label}
  </StyledLink>

  if (!!props.href && !props.disabled) {
    return (
      <a href={props.href} key={props.key} target={props.target} style={{ textDecoration: 'none', cursor: 'pointer' }}>
        {content}
      </a>
    )
  }

  if (!!props.linkTo && !props.disabled) {
    return (
      <NavLink to={props.linkTo} key={props.key} style={{ textDecoration: 'none', cursor: 'pointer' }}>
        {content}
      </NavLink>
    )
  }

  if (!!props.onClick && !props.disabled) {
    return <NavButton onClick={props.onClick} style={{ cursor: 'pointer' }}>
      {content}
    </NavButton>
  }

  return <React.Fragment>{content}</React.Fragment>
}

const NavButton = styled.button`
  background: transparent;
  border: none;
  margin: 0;
`

const LogoWrapper = styled.a`
  text-align: center;
  width: 100%;
  display: block;
  padding: ${spacing(10)} 0;
  img {
    width: 59px;
    height: 73px;
  }
`

const NavbarWrapper = styled.nav`
  display: flex;
  overflow: scroll;
  position: fixed;
  z-index: 999;
  flex-direction: column;
  justify-content: space-between;
  background: ${Color.backgrounds.blues.background2};
  border-radius: ${spacing(5)} 0 0 ${spacing(5)};
  max-width: ${spacing(NAVBAR_WIDTH)};
  min-width: ${spacing(NAVBAR_WIDTH)};
  box-sizing: border-box;
  min-height: calc(100vh - ${spacing(10)});
  height: calc(100vh - ${spacing(10)});
`

const MainNav = styled.div``

const SettingsNav = styled.div`
  margin: ${spacing(5)} 0;
`

const NavIcon = styled.i`
  width: ${spacing(6)};
  height: ${spacing(6)};
  margin-right: ${spacing(3)};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled.div<{ disabled?: boolean }>`
  ${Typography.paragraphsAndCaptions.paragraph3};
  color: ${Color.primaryColors.ink6};
  padding: 13px 0px 13px 30px;
  text-decoration: none !important;
  margin: 7px 0;
  display: flex;
  opacity: ${({ disabled }) => !!disabled ? 0.5 : 1};
  pointer-events: ${({ disabled }) => !!disabled ? 'none' : 'auto'};

  .active & {
    background: ${Color.neutralColors.blackAndWhite.white0};
    color: ${Color.primaryColors.ink6};
    border-right: 5px solid ${Color.accentColors.blue4};

    i {
      color: ${Color.accentColors.blue4};
    }
  }
`