import React from 'react'
import styled from 'styled-components'
import {
  ColorPaletteMain as ColorMain,
  ColorPaletteTertiaryColors as ColorSub
} from '../styles/ColorPalette'

interface ProgressbarProps {
  percentCompleted: number
}

export const Progressbar: React.FC<ProgressbarProps> = ({
  percentCompleted
}) => {
  return (
    <Container>
      <Inner completed={percentCompleted} />
      <Overloaded percentCompleted={percentCompleted} />
    </Container>
  );
}

const Container = styled.div`
  background: ${ColorMain.neutralColors.grays.gray0};
  border-radius: 10px;
  height: 10px;
  position: relative;
  width: 100%;
`

const Inner = styled.div<{
  completed?: number
}>`
  background: ${ColorMain.primaryColors.ink6};
  border-radius: 10px;
  height: 10px;
  width: ${props => Math.min(props.completed ?? 0, 100)}%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Overloaded = styled.div<{
  percentCompleted?: number;
}>`
  background: ${ColorSub.reds.red4};
  border-radius: 12px;
  height: 10px;
  width: ${(props) => Math.min((props.percentCompleted ?? 100) - 100, 100.5)}%;
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
`;