import React from 'react'
import styled from 'styled-components'
import {
  ColorPaletteMain as ColorMain,
} from "../styles/ColorPalette";

interface PurchaseOrderItemProps {
  data: {
    date: string
    orderNumber: string
    amount: string
    status: string
    invoiceLink: string
  }
}

export const PurchaseOrderItem:React.FC<PurchaseOrderItemProps> = ({ data }) => {
  return (
    <Wrapper>
      {Object.entries(data).map(([key, value]) => (
        (key !== "invoiceLink")
          ? (<Item>{value}</Item>)
          : (<InvoiceLink>{value}</InvoiceLink>)
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${ColorMain.neutralColors.grays.gray0};
`

const Item = styled.div`
  width: 100%;
  padding: 30px 0 19px 0;
`

const InvoiceLink = styled.div`
  width: 100%;
  padding: 30px 0 19px 0;
  text-decoration: underline;
  font-weight: bold;
`