import React from 'react'
import styled from "styled-components"
import { Typography } from '../styles/TextStyles'
import {
  ColorPaletteMain as ColorMain,
  ColorPaletteTertiaryColors as ColorSub
} from "../styles/ColorPalette"

interface ButtonProps {
  onAction: () => void
  children: string
  colorGradient?: keyof typeof ColorMain.gradients
  isInactive?: boolean
  isError?: boolean
  actionType?: "primary" | "secondary" | "tertiary"
}

// There are basically two types of different buttons and the second one with a choice between border and no border (incl. error state)

export const Button:React.FC<ButtonProps> = (props: ButtonProps) => {

  const typeOfAction = props.actionType ?? "primary"
  const isPrimaryAction = typeOfAction === "primary"
  const buttonHasBorder = props.actionType === "secondary"

  return (
    <React.Fragment>
      {isPrimaryAction ? (
        <Wrapper onClick={props.onAction} isInactive={props.isInactive}>
          <WrapperInner colorGradient={props.colorGradient}>
            <Label isPrimaryAction={isPrimaryAction}>{props.children}</Label>
          </WrapperInner>
        </Wrapper>
      ) : (
        <Wrapper isInactive={props.isInactive}>
          <WrapperInner
            isError={props.isError}
            backgroundIsTransparent
            border={buttonHasBorder}
          >
            <Label isError={props.isError}>{props.children}</Label>
          </WrapperInner>
        </Wrapper>
      )}
    </React.Fragment>
  );
}


const Wrapper = styled.div<{isInactive?: boolean}>`
  opacity: ${(props) => !!props.isInactive ? "0.5" : "1"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => !!props.isInactive ? "auto" : "pointer"};
`

const WrapperInner = styled.div<{
  colorGradient?: keyof typeof ColorMain.gradients;
  isError?: boolean;
  backgroundIsTransparent?: boolean;
  border?: boolean;
}>`
  background: ${(props) => !props.backgroundIsTransparent ? ColorMain.gradients[`${props.colorGradient ?? "blueGreen"}`]  : "transparent"};
  box-shadow: ${(props) => !props.backgroundIsTransparent ? "2px 4px 4px rgba(0, 0, 0, 0.08)" : "none"};
  border: ${(props) => props.border ? "2px solid" : "none"};
  border-color: ${(props) => props.isError ? `${ColorSub.reds.red4}` : `${ColorMain.accentColors.blue4}`};
  border-radius: 30px;
`

const Label = styled.div<{
  isError?: boolean;
  isPrimaryAction?: boolean;
}>`
  ${Typography.actions.buttonText};
  line-height: 22px;
  color: ${(props) => props.isError ? `${ColorSub.reds.red4}` : `${ColorSub.blues.blue4}`};
  ${(props) => props.isPrimaryAction ? `color: ${ColorMain.neutralColors.blackAndWhite.white0}` : ''};
  padding: 13px 32px;
`;