import React from 'react'
import styled from 'styled-components'
import { GlobalStyle } from '../styles/GlobalStyle'
import { Navigation } from './Navigation'
import { Content } from './Content'
import { UserInfo } from './UserInfo'

export const Layout = (props: any) => {
  return (
    <Wrapper>
      <GlobalStyle />
      <Navigation />
      <UserInfo />
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 1728px;
  display: flex;
  padding: 20px;
`
