import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ColorPaletteMain as ColorMain } from '../styles/ColorPalette'
import { Typography } from '../styles/TextStyles'

interface ToggleProps {
  onStateLabel: string;
  offStateLabel: string;
  onChange: (input: boolean) => void;
}

// If required lift state management to higher order component for performance
export const Toggle: React.FC<ToggleProps> = ({
  onStateLabel, offStateLabel, onChange
}) => {
  const [isOn, setIsOn] = useState<boolean>(true)

  const clickHandler = (value: boolean) => {
    console.log(value)
    setIsOn(value)
  }

  useEffect(() => {
    onChange(isOn)
  }, [isOn, onChange])

  return (
    <Wrapper>
      <ToggleBackground isChosen={isOn} />
      <ToggleItem onClick={() => { clickHandler(true) }}>
        {offStateLabel ?? "off"}
      </ToggleItem>
      <ToggleItem onClick={() => { clickHandler(false) }}>
        {onStateLabel ?? "on"}
      </ToggleItem>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
`

const ToggleBackground = styled.div<{ isChosen: boolean }>`
  background: ${ColorMain.backgrounds.blues.background4};
  border-radius: 4px;
  height: 100%;
  position: absolute;
  transform: ${props => props.isChosen ? `translateX(0)` : `translateX(100%)`};
  transition: transform 0.3s ease-in-out;
  width: 50%;
  z-index: 0;
`

const ToggleItem = styled.span`
  ${Typography.paragraphsAndCaptions.paragraph3};
  padding: 10px 12px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  z-index: 1;
`;