import React, { useRef } from 'react'
import styled from 'styled-components'
import { GlobalStyle } from '../styles/GlobalStyle';

interface MobileGateProps {
  children: React.ReactElement
}

export const MobileGate: React.FC<MobileGateProps> = (props) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  console.log(windowSize)
  if (windowSize.current[0] > 700) {
    return props.children
  }
  return <Wrapper>
    <GlobalStyle />
    <img src="images/logo.png" alt="hope logo" />
    <TextWrapper>
      <h3>Willkommen auf dem Hope Dashboard!</h3>
      <p><strong>Leider können Sie das Dashboard nur am Desktop nutzen.</strong></p>
    </TextWrapper>
  </Wrapper>
}

const TextWrapper = styled.div`
  margin-top: 64px;
  max-width: 320px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  /* text-align: center; */
  height: 100vh;
`

