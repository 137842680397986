import React from 'react'
import styled from 'styled-components'
import { NAVBAR_WIDTH } from '../styles/Constants';
import { spacing } from '../styles/Spacing';

export const Content = (props: any) => {
  return (
    <Wrapper>
      <HeaderWrapper>
      </HeaderWrapper>
      <ContentWrapper>{props.children}</ContentWrapper>
    </Wrapper>
  );
}

const HeaderWrapper = styled.div`
  padding: 34px;
`

const ContentWrapper = styled.main`
  padding: 0 60px;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 0 ${spacing(NAVBAR_WIDTH + 5)};
`;
