import React from 'react'
import styled from 'styled-components'
import { ColorPaletteMain as ColorMain } from "../styles/ColorPalette";
import { Card } from './Card'

interface ModalProps {
  modalIsShown: boolean
  children?: React.ReactNode
}

export const Modal:React.FC<ModalProps> = ({
  modalIsShown, children
}) => {
  if (!modalIsShown) {
    return null
  }
  return (
    <React.Fragment>
      <ModalOverlay />
      <ContentWrapper>
        <Content>{children}</Content>
      </ContentWrapper>
    </React.Fragment>
  );
}

const ModalOverlay = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${ColorMain.neutralColors.blackAndWhite.black0};
  opacity: 0.6;
  transition: 1s;
`

const ContentWrapper = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled(Card)`
  background: ${ColorMain.neutralColors.blackAndWhite.white0};
  max-width: 367px;
`;