import React from 'react'
import styled from "styled-components"

interface CardProps {
  children: React.ReactNode
  className?: any
  alignment?: "flex-start" | "flex-end" | "center",
  maxWidth?: string
}

export const Card: React.FC<CardProps> = ({
  children, className, alignment, maxWidth
}) => {
  return(
    <CardWrapper className={className} maxWidth={maxWidth}>
      <CardInner contentAlignment={alignment}>
        {children}
      </CardInner>
    </CardWrapper>
  )
}

const CardWrapper = styled.div<{
  maxWidth?: string
}>`
  box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${({maxWidth}) => maxWidth ?? '100%'}
`

const CardInner = styled.div<{
  contentAlignment?: "flex-start" | "flex-end" | "center"
}>`
  align-items: ${props => props.contentAlignment ?? "center"};
  padding: 32px;
  display: flex;
  flex-direction: column;
`