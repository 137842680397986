import React from 'react'
import styled from 'styled-components'
import { Card } from './Card'
import { Progressbar } from './Progressbar'
import { Typography } from '../styles/TextStyles'
import {
  ColorPaletteMain as ColorMain,
  ColorPaletteTertiaryColors as ColorSub
} from '../styles/ColorPalette'

interface PackageCardProps {
  purchasedPackage: boolean
  currentCodes: number
  maximumCodes?: number
  expirationDate: string
}

export const PackageCard: React.FC<PackageCardProps> = ({
  currentCodes, maximumCodes, purchasedPackage, expirationDate
}) => {
  const packagePercentage = (currentCodes / (maximumCodes ?? currentCodes)) * 100;
  const isHighlighted: boolean = packagePercentage > 100 || !purchasedPackage;

  return purchasedPackage ? (
    <HalfWidthWrapper alignment="flex-start" isHighlighted={isHighlighted}>
      {isHighlighted && <AttentionTitle>Account on overdraft</AttentionTitle>}
      <PatientInfoSection>
        <h5>
          {currentCodes}
          <ColorNormalizer> / {maximumCodes}</ColorNormalizer>
        </h5>
        <Label>
          {!isHighlighted
          ? `${(maximumCodes ?? 0) - currentCodes} patients left`
          : `${currentCodes - (maximumCodes ?? 0)} patients over`}
        </Label>
      </PatientInfoSection>
      <Progressbar percentCompleted={packagePercentage} />
      <CodePaymentInfoSection>
        {!isHighlighted ? (
          `Package expires on: ${expirationDate}`
        ) : (
          <React.Fragment>
            <div>
              Package expired on: <strong>{expirationDate}</strong>
            </div>
            <strong>€25.00 / patient</strong>
          </React.Fragment>
        )}
      </CodePaymentInfoSection>
    </HalfWidthWrapper>
  ) : (
    <HalfWidthWrapper alignment="flex-start" isHighlighted={isHighlighted}>
      <AttentionTitle>No active package</AttentionTitle>
      <EyebrowTitle>January 2023</EyebrowTitle>
      <Title>43 QR Codes</Title>
      <CodePaymentInfoSection>
        <strong>€25.00 / Patient</strong>
      </CodePaymentInfoSection>
    </HalfWidthWrapper>
  );
}

const HalfWidthWrapper = styled(Card)<{isHighlighted?: boolean }>`
  color: ${props => props.isHighlighted
    ? `${ColorSub.reds.red4}`
    : `${ColorMain.primaryColors.ink6}`};
  width: 50%;
  padding: 0 9px;
`

const AttentionTitle = styled.h6`
  padding: 9px 0 18px 0;
`

const EyebrowTitle = styled.div`
  ${Typography.subtitles.subtitleSmall2};
  color: ${ColorMain.primaryColors.ink6};
  padding: 7px 0;
`;

const Title = styled.h3`
  color: ${ColorMain.primaryColors.ink6};
`

const Label = styled.span`
  ${Typography.paragraphsAndCaptions.paragraph1};
  color: ${ColorMain.primaryColors.ink6};
`

const PatientInfoSection = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding: 0px 0 14px;

  &h5 {
    display: inline-block;
  }
`

const CodePaymentInfoSection = styled.p`
  ${Typography.paragraphsAndCaptions.paragraph1};
  margin: 0;
  padding-top: 34px;
`

const ColorNormalizer = styled.span`
  color: ${ColorMain.primaryColors.ink6};
`