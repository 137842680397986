import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout } from './layout/Layout'
// import { NotFoundRoute } from './pages/404'
// import { Call } from './pages/Call'
// import { Chat } from './pages/Chat'
// import { ClinicDetails } from './pages/ClinicDetails'
// import { Dashboard} from './pages/Dashboard'
// import { Feedback } from './pages/Feedback'
// import { Help } from './pages/Help'
// import { Profile } from './pages/Profile'
// import { ShoppingCart } from "./pages/ShoppingCart"
// import { UserManagement } from './pages/UserManagement'
import { ManagePurchases } from './pages/ManagePurchases'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from 'react-query'
import { MobileGate } from './components/MobileGate'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  );


const queryClient = new QueryClient()

const AuthLayout = withAuthenticationRequired(Layout)
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="hopeapp.eu.auth0.com"
      clientId="oEbKJP7eockHhR3pxFbePuJVb64NkA56"
      audience="https://dashboard.hope-app.cloud"
      scope="quota:read"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <MobileGate>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthLayout>
              <Routes>
                {/* <Route path="/" element={<Dashboard />} /> */}
                <Route path="/" element={<ManagePurchases />} />
                {/* <Route path="/purchases" element={<ManagePurchases />} /> */}
                {/* <Route path="/shopping-cart" element={<ShoppingCart />} /> */}
                {/* <Route path="/chat" element={<Chat />} /> */}
                {/* <Route path="/call" element={<Call />} /> */}
                {/* <Route path="/clinic-details" element={<ClinicDetails />} /> */}
                {/* <Route path="/feedback" element={<Feedback />} /> */}
                {/* <Route path="/user-management" element={<UserManagement />} /> */}
                {/* <Route path="/help" element={<Help />} /> */}
                {/* <Route path="/profile" element={<Profile />} /> */}
                {/* <Route path="*" element={<NotFoundRoute />} /> */}
              </Routes>
            </AuthLayout>
          </BrowserRouter>
        </QueryClientProvider>
      </MobileGate>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
