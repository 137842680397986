import React from 'react'
import styled from 'styled-components'
import { Typography } from '../styles/TextStyles'
import { spacing } from '../styles/Spacing'
import { useAuth0 } from "@auth0/auth0-react";

//TODO: add hope logo to navigation
export const UserInfo: React.FC = () => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return null
  }
  return <UserInfoWrapper>
    <UserPicture src={user?.picture} />
    {user?.name}
  </UserInfoWrapper>
}

const UserPicture = styled.img`
  width: ${spacing(12)};
  height: ${spacing(12)};
  border-radius: ${spacing(6)};
  margin-right: ${spacing(5)};
`

const UserInfoWrapper = styled.div`
  position: absolute;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${spacing(10)};
  right: ${spacing(10)};
  ${Typography.paragraphsAndCaptions.paragraph3};
  position: fixed;
  z-index: 99999;
`