import React, { useState, useEffect } from 'react'
import OutsideClickHandler from "react-outside-click-handler";
import styled from 'styled-components'
import { Typography } from '../styles/TextStyles'
import { ColorPaletteMain as Color } from '../styles/ColorPalette'

interface NumberPickerProps {
  minimumValue: number
  maximumValue: number
  initialValue?: number
  onChange(value: number): void
  isSmall?: boolean
}

export const NumberPicker: React.FC<NumberPickerProps> = ({
  maximumValue, minimumValue, initialValue, onChange, isSmall
}) => {
  const [count, setCount] = useState<number>(initialValue ?? 0)
  const [inputElement, setInputElement] = useState<boolean>(false)

  const incrementCount = () => setCount(Math.min(count + 1, (maximumValue ?? 100)))
  const decrementCount = () => setCount(Math.max(0, Math.max(count - 1, (minimumValue ?? 0))))

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value)
    setCount(inputValue)
  }

  const inputClickHandler = () => setInputElement(!inputElement)
  const clickOutsideInputHandler = () => {
    if (count > maximumValue) {
      setCount(maximumValue)
      return
    }
    if (count < minimumValue) {
      setCount(minimumValue)
      return
    }
    setCount(count)
  }

  useEffect(() => {
    console.log(count)
    if (isNaN(count)) {
      onChange(minimumValue)
      return
    }
    onChange(Math.max(Math.min(count, maximumValue), minimumValue))
  }, [count])

  return (
    <Wrapper small={isSmall ?? false}>
      <OperationHandler small={isSmall ?? false} onClick={decrementCount}>
        <GradientIcon small={isSmall ?? false} className="fas fa-minus" />
      </OperationHandler>
      {inputElement ? (
        <OutsideClickHandler onOutsideClick={clickOutsideInputHandler}>
          {isSmall ?? false ? (
            <ValueTextInputSmall
              value={count}
              type="number"
              onChange={inputChangeHandler}
            />
          ) : (
            <ValueTextInput
              value={count}
              type="number"
              onChange={inputChangeHandler}
            />
          )}
        </OutsideClickHandler>
      ) : isSmall ?? false ? (
        <ValueWrapperSmall onClick={inputClickHandler}>
          {count}
        </ValueWrapperSmall>
      ) : (
        <ValueWrapper onClick={inputClickHandler}>{count}</ValueWrapper>
      )}
      <OperationHandler small={isSmall ?? false} onClick={incrementCount}>
        <GradientIcon small={isSmall ?? false} className="fas fa-plus" />
      </OperationHandler>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ small: boolean }>`
  ${(props) =>
    props.small
      ? {
          borderRadius: "4px",
          padding: "10px 10px",
          width: "94px",
        }
      : {
          borderRadius: "12px",
          padding: "26px 0",
          width: "100%"
        }}
  box-sizing: border-box;
  background: ${Color.backgrounds.inks.background1};
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 100%;
  }
`;

const OperationHandler = styled.div<{ small: boolean }>`
  ${(props) => props.small ? {
    width: "20px",
    height: "20px"
  }: {
    width: "48px",
    height: "48px"
  }}
  display: flex;
  align-items: center;
  justify-content: center;
`

const GradientIcon = styled.i<{ small: boolean }>`
  ${(props) => props.small ? {
    background: `${Color.primaryColors.ink6}`,
    fontSize: "10px"
  } : {
    background: `${Color.gradients.blueGreen}`,
    fontSize: "20px"
  }};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ValueTextInputBase = styled.input`
  box-sizing: border-box;
  display: inline;
  background: ${Color.backgrounds.inks.background1};
  border: 0;
  text-align: center;
  font-weight: 800;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &[type="number"] {
    padding: 0;
    -moz-appearance: textfield;
    width: 100%;
  }

  &:focus {
    outline: 0;
  }
`

const ValueTextInput = styled(ValueTextInputBase)`
  ${Typography.headers.h4};
  font-weight: 800;
`;

const ValueTextInputSmall = styled(ValueTextInputBase)`
  ${Typography.paragraphsAndCaptions.paragraph1};
`;

const ValueWrapperBase = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

const ValueWrapper = styled(ValueWrapperBase)`
  ${Typography.headers.h4};
  font-weight: 800;
`

const ValueWrapperSmall = styled(ValueWrapperBase)`
  ${Typography.paragraphsAndCaptions.paragraph1};
`